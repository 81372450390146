@font-face {
  font-family: "CustomFont";
  src: url('../public/fonts/Henderson-Sans-Regular.otf') format('opentype');
  font-weight: 400;
  /* Regular weight */
}

@font-face {
  font-family: "CustomFont";
  src: url('../public/fonts/Henderson-Sans-Semi-Bold.otf') format('opentype');
  font-weight: 500;
  /* Regular weight */
}

@font-face {
  font-family: "CustomFont";
  src: url('../public/fonts/Henderson-Sans-Semi-Bold.otf') format('opentype');
  font-weight: 600;
  /* Regular weight */
}

@font-face {
  font-family: "CustomFont";
  src: url('../public/fonts/Henderson-Sans-Bold.otf') format('opentype');
  font-weight: 700;
  /* Regular weight */
}

@font-face {
  font-family: "CustomFont";
  src: url('../public/fonts/Henderson-Sans-Bold.otf') format('opentype');
  font-weight: 800;
  /* Regular weight */
}



body {
  margin: 0;
  padding: 0;
  font-family: "CustomFont", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100% !important;
  /* font-size: larger; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}